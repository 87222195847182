import { Stack, Typography } from '@mui/material';

/**
 * @returns Component Not Found Page
 */
export function NotFoundPage() {
  return (
    <Stack width={'100%'} height={'100vh'} flex={1} alignItems={'center'} justifyContent={'center'}>
      <Typography>😢 Page not found.</Typography>
    </Stack>
  );
}
