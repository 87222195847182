import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import { TextError } from './TextError';

type InputPasswordProps = FilledInputProps & {
  title?: string;
  inputError?: string;
};
export function InputPassword(props: InputPasswordProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <FormControl variant="filled">
      <StyledInputLabel htmlFor="filled-adornment-password" inputError={props?.inputError}>
        {props?.title || 'Password'}
      </StyledInputLabel>
      <StyledFilledInput
        {...omit(props, 'title')}
        error={!!props.inputError}
        id="filled-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={showPassword ? 'hide the password' : 'display the password'}
              onClick={handleClickShowPassword}
              edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {props?.inputError && (
        <FormHelperText error={!!props?.inputError} sx={{ ml: 0, maxWidth: '100%' }}>
          <TextError errorText={props?.inputError} />
        </FormHelperText>
      )}
    </FormControl>
  );
}

const StyledFilledInput = styled(FilledInput)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiInputBase-input': {
    width: 'calc(100% - 12px)',
    backgroundColor: '#E6E0E9 !important',
    color: '#1D1B20 !important',
    borderRadius: '4px 0 0 0'
  },
  '& .MuiInputAdornment-root': {
    width: '50px',
    backgroundColor: '#E6E0E9 !important'
  },
  '& .Mui-disabled': {
    opacity: 0.5
  }
}));

const StyledInputLabel = styled(InputLabel)(({ inputError }: { inputError?: string }) => ({
  fontSize: 14,
  color: inputError ? '#ff1744' : '#1D1B20'
}));
