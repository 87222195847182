import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { forgotPassword } from 'services/auth';

export const useInjection = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const role = searchParams.get('role');
  const submitForgot = useCallback(
    async (data: { email: string }) => {
      if (!role) return;
      await forgotPassword({ email: data.email, role });
      setFlash({
        type: 'success',
        message: 'You have successfully sent the forgot password request, please check your inbox.'
      });
      navigate('/login');
      // eslint-disable-next-line
        }, [location, role]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(submitForgot, []);
  return {
    handleSubmitForm
  };
};
