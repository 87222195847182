export { ReactComponent as BackIcon } from 'assets/icons/back.svg';
export { ReactComponent as UserIcon } from 'assets/icons/user.svg';
export { ReactComponent as AddIcon } from 'assets/icons/add.svg';
export { ReactComponent as GroupUsersIcon } from 'assets/icons/group_users.svg';
export { ReactComponent as NavItemsIcon } from 'assets/icons/nav_item.svg';
export { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
export { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
export { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
export { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
export { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
