import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFlash } from 'contexts/flash';
import { useCallback, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { resetPassword } from 'services/auth';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();

export const useInjection = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const submitForgot = useCallback(
    async (data: { password: string }) => {
      if (!token) return;
      const res = await resetPassword({ password: data.password, token });
      storage.setAccessToken(res?.accessToken);
      storage.setRefreshToken(res?.refreshToken);
      storage.setRole(res?.role);
      navigate('/enterprise-accounts');
      setFlash({
        type: 'success',
        message: 'You have successfully reset your password.'
      });
      // eslint-disable-next-line
        }, [location, token]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(submitForgot, []);
  return {
    handleSubmitForm,
    showPassword,
    setShowPassword
  };
};
