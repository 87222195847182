import axios from 'axios';

export const loginWithAdmin = async (payload: { email: string; password: string }) => {
  const { data } = await axios.post('/auth/admins/signin', payload);
  return data;
};

export const loginWithEnterprise = async (payload: { username: string; password: string }) => {
  const { data } = await axios.post('/auth/signin', payload);
  return data;
};

export const getUserInfo = async () => {
  const { data } = await axios.get('/auth/me');
  return data;
};
