import { useLocation, useNavigate } from 'react-router-dom';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const useInjection = () => {
  const location = useLocation();
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const submitForgot = useCallback(
    async (data: { email: string }) => {
      console.log({ data });
      setFlash({
        type: 'success',
        message: 'You have successfully sent the forgot password request, please check your inbox.'
      });
      navigate('/login');
      // eslint-disable-next-line
        }, [location]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(submitForgot, []);
  return {
    handleSubmitForm
  };
};
