import { LoginPage } from 'app/pages/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LayoutAdmin } from 'app/components/templates/LayoutAdmin';
import { RequireAdminAuth } from 'app/components/modules/AuthRouter';
import { HelmetProvider } from 'react-helmet-async';
import { ReportPage } from 'app/pages/ReportPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { ForgotPasswordPage } from 'app/pages/ForgotPasswordPage';
import { AccountPage } from 'app/pages/AccountPage';
import { UsersPage } from 'app/pages/UsersPage';
import { AdminsPage } from 'app/pages/AdminsPage';
import { NewAccountPage } from 'app/pages/NewAccountPage';
import { DashboardPage } from 'app/pages/Dashboard';
import { LogsPage } from 'app/pages/LogsPage';
import { MyProfilePage } from 'app/pages/MyProfilePage';
import { ResetPasswordPage } from 'app/pages/ResetPasswordPage';

export default function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route
          path="*"
          errorElement={<NotFoundPage />}
          element={
            <RequireAdminAuth>
              <LayoutAdmin>
                <Routes>
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="reports" element={<ReportPage />} />
                  <Route path="enterprise-accounts" element={<AccountPage />} />
                  <Route path="new-account" element={<NewAccountPage />} />
                  <Route path="logs" element={<LogsPage />} />
                  <Route path="users" element={<UsersPage />} />
                  <Route path="admin-accounts" element={<AdminsPage />} />
                  <Route path="my-profile" element={<MyProfilePage />} />
                  <Route path="*" element={<Navigate to="dashboard" />} />
                </Routes>
              </LayoutAdmin>
            </RequireAdminAuth>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Routes>
    </HelmetProvider>
  );
}
