/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';

import { Board } from '../Board';

const colors = ['#ab0349', '#2880FE', '#037f0f', '#4DC0BD', '#3b037f', '#7f0360', '#b8a906'];

const DoughnutChart = ({ projects, loading, heading, innerSize, pieSize }: any) => {
  // console.log("projects:",projects)
  const totalCards = useMemo(
    () => projects.reduce((item: any, el: any) => item + el.totalCards, 0),
    [projects]
  );
  // console.log(totalCards);
  const data = useMemo(
    () =>
      projects.map((item: any, index: number) => ({
        y: item.count || item.value,
        name: item.language || item.text,
        color: colors[index],
        label: item.count || item.value
      })),
    [projects, totalCards]
  );
  // console.log(data);
  const options = useMemo(
    () => ({
      chart: {
        plotShadow: false,
        type: 'pie',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0]
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        align: 'left',
        layout: 'vertical',
        verticalAlign: 'bottom',
        itemMarginTop: 2,
        itemMarginBottom: 2,
        itemStyle: {
          fontSize: '14px'
        },
        labelFormat: '{name} : {percentage:.1f} %'
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            connectorColor: 'silver'
          }
        }
      },
      series: [
        {
          sliced: true,
          type: 'pie',
          dataLabels: {
            connectorPadding: 0,
            position: 'left',
            distance: -60,
            enabled: false,
            // format: '<b>{point.name}</b>',
            allowPointSelect: true,
            cursor: 'pointer',
            color: '#fff',
            borderWidth: 0
          },
          data,
          showInLegend: true,
          size: pieSize,
          innerSize
        }
      ]
    }),
    [data]
  );

  return (
    <Board loading={loading} heading={heading}>
      <PieChart highcharts={Highcharts} options={options} />
    </Board>
  );
};

export default DoughnutChart;
