import React, { useEffect, useMemo, useState } from 'react';
import { User } from 'types/user';
import { Stack, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import { ButtonCustom } from '../elements/ButtonCustom';
import { InputSelect } from '../elements/InputSelect';

const STATUS_OPTIONS = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' }
];

export type UserDetailProps = {
  user: User;
  onChangeStatus: () => void;
  onClose: () => void;
};
function UserDetail({ user, onClose }: UserDetailProps) {
  const [status, setStatus] = useState(0);
  useEffect(() => {
    setStatus(user.status);
  }, [user.status]);

  const subscriptionType = useMemo(() => {
    if (!user.subscriptions[0]) return '';
    if (user.subscriptions[0].isTrial) return 'Trial';
    return user.subscriptions[0].frequency === 'y' ? 'Yearly' : 'Monthly';
  }, [user]);
  return (
    <Stack gap={2} sx={{ width: 540 }}>
      <Stack direction={'row'}>
        <Stack style={{ width: '65%' }} gap={2}>
          <Typography>ID: {user.id}</Typography>
          <Typography>
            Customer Name: {[user.profile.firstName, user.profile.lastName].join(' ')}
          </Typography>
          <Typography>Email: {user.email}</Typography>
          <Typography>Subscription Type: {subscriptionType}</Typography>
          <Typography>Last Visit: {dayjs(user.lastvisit).format('MM/DD/YYYY')}</Typography>
          <Typography>Created: {dayjs(user.created).format('MM/DD/YYYY')}</Typography>
          <Typography>Last Updated: {dayjs(user.updated).format('MM/DD/YYYY')}</Typography>
        </Stack>
        <Box style={{ width: '25%' }}>
          <InputSelect
            title={'Status'}
            options={STATUS_OPTIONS}
            value={status}
            onChange={(e) => setStatus(e.target.value as number)}
          />
        </Box>
      </Stack>
      <Stack gap={2} direction={'row-reverse'}>
        <ButtonCustom variant={'contained'} style={{ width: '20%' }} type="submit">
          Save
        </ButtonCustom>
        <ButtonCustom style={{ width: '20%' }} onClick={onClose}>
          Cancel
        </ButtonCustom>
      </Stack>
    </Stack>
  );
}

export default UserDetail;
