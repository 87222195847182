import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';

import LineChart from 'app/components/modules/LineChart';
import DoughnutChart from 'app/components/modules/PieChart';
import { WordCloudChart } from 'app/components/modules/WordCloud';
import monthTips from '../../../../../data/monthTips.json';
import { useInjection } from './hook';
import CustomDatePicker from 'app/components/elements/InputDate';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';

export const Reports = () => {
  const {
    thisMonth,
    startDate,
    endDate,
    loading,
    talk2TextFreq,
    text2SpeechFreq,
    text2TranslateFreq,
    conversations,
    replacePhrases,
    emotions,
    replaceLanguage,
    replaceTranslatedLanguage,
    dailyConversationsConverted,
    reallyFinalMonthOrder,
    setStartDate,
    setEndDate,
    onFilter
  } = useInjection();
  return (
    <>
      <Heading>
        <HeadingText variant={'h1'}>Analytics Dashboard</HeadingText>
        {/*<Typography>Report Date: {startDate}</Typography>*/}
      </Heading>
      <Stack direction={'row'} py={2} gap={4} alignItems={'flex-end'}>
        <CustomDatePicker title={'Start Date'} value={startDate} onChange={setStartDate} />
        <CustomDatePicker title={'End Date'} value={endDate} onChange={setEndDate} />
        <ButtonCustom variant={'contained'} sx={{ maxWidth: 80 }} onClick={() => onFilter()}>
          Search
        </ButtonCustom>
      </Stack>
      <Box mt={1.5}>
        <SummaryTitle>Overall Summary</SummaryTitle>
      </Box>
      <Grid mt={1} container spacing={{ xs: 1, sm: 2, xl: 3 }}>
        <Grid sm={12} md={6} xl={3}>
          <AnalysticBox>
            <Stack direction={'row'} gap={'20px'}>
              <IconBox style={{ background: '#8995ff' }}>
                <Icon src="/images/message.svg" className="msg_icon" alt="message" />
              </IconBox>
              <Stack gap={'0.75rem'}>
                <span>
                  Total number of Conversations
                  <sup>*</sup>
                </span>
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <NumberCount variant={'h3'} id="conversations-total">
                      {conversations.length}
                    </NumberCount>
                  )}
                </>
              </Stack>
            </Stack>
            <SubText> * Conversations end once activity stops for 15 minutes.</SubText>
          </AnalysticBox>
        </Grid>
        <Grid sm={12} md={6} xl={3}>
          <AnalysticBox>
            <Stack direction={'row'} gap={'20px'}>
              <IconBox style={{ background: '#048a16' }}>
                <Icon src="/images/mic.svg" className="green_clock_icon" alt="speak" />
              </IconBox>
              <Stack gap={'0.75rem'}>
                <span>Frequency of talk-to-text</span>
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <NumberCount variant={'h3'} id="talk-2-text">
                      {talk2TextFreq}
                    </NumberCount>
                  )}
                </>
              </Stack>
            </Stack>
          </AnalysticBox>
        </Grid>
        <Grid sm={12} md={6} xl={3}>
          <AnalysticBox>
            <Stack direction={'row'} gap={'20px'}>
              <IconBox style={{ background: '#06048a' }}>
                <Icon src="/images/speaker.svg" className="drkblue_clock_icon" alt="talk" />
              </IconBox>
              <Stack gap={'0.75rem'}>
                <span>Frequency of text-to-speech</span>
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <NumberCount variant={'h3'} id="text-2-speech">
                      {text2SpeechFreq}
                    </NumberCount>
                  )}
                </>
              </Stack>
            </Stack>
          </AnalysticBox>
        </Grid>
        <Grid sm={12} md={6} xl={3}>
          <AnalysticBox>
            <Stack direction={'row'} gap={'20px'}>
              <IconBox style={{ background: '#8995ff' }}>
                <Icon src="/images/translate.svg" className="brown_clock_icon" alt="translate" />{' '}
              </IconBox>
              <Stack gap={'0.75rem'}>
                <span>Frequency of text-to-translate</span>
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <NumberCount variant={'h3'} id="text-2-translate">
                      {text2TranslateFreq}
                    </NumberCount>
                  )}
                </>
              </Stack>
            </Stack>
          </AnalysticBox>
        </Grid>
      </Grid>
      <Stack mt={4} gap={4}>
        <WordCloudChart
          projects={replacePhrases}
          loading={loading}
          heading="Common Phrases Last Six Months"
        />
        <DoughnutChart
          projects={emotions}
          loading={loading}
          heading="Most Common Experience Categories"
          innerSize="50%"
          pieSize="100%"
        />
        <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
          <ChartRowItem>
            <DoughnutChart
              projects={replaceLanguage}
              loading={loading}
              heading="Most Common Languages Spoken"
              innerSize="0"
              pieSize="60%"
            />
          </ChartRowItem>
          <ChartRowItem>
            <DoughnutChart
              projects={replaceTranslatedLanguage}
              loading={loading}
              heading="Most Common Languages Translated"
              innerSize="0"
              pieSize="60%"
            />
          </ChartRowItem>
        </Stack>
        <LineChart
          heading={'Daily Conversations'}
          projects={dailyConversationsConverted}
          loading={loading}
          units="freq"
          period="day"
        />
        <LineChart
          heading={'Conversations Per Month (last 12 months)'}
          projects={reallyFinalMonthOrder}
          loading={loading}
          units="freq"
          period="month"
          thisMonth={thisMonth}
        />
        <MonthTip>{monthTips[thisMonth]}</MonthTip>
        <div className="min-h-screen min-w-screen">
          <div className="h-12 w-full bg-footer">
            <div className="text-white w-full pt-2 pl-5">
              Copyright 2024 Vidatak LLC | All Rights Reserved | www.vidatalk.com
            </div>
          </div>
        </div>
      </Stack>
    </>
  );
};

const Heading = styled(Stack)(() => ({
  padding: '8px 16px',
  gap: 8,
  alignItems: 'center',
  flexDirection: 'row',
  background: '#CCE2FA'
}));
const HeadingText = styled(Typography)(() => ({
  lineHeight: 1,
  fontWeight: 900,
  fontSize: 24
}));
const SummaryTitle = styled(Typography)(() => ({
  lineHeight: '24px',
  fontWeight: 700,
  fontSize: 24,
  padding: '0.5rem 1.5rem',
  borderBottom: '1px solid rgba(229, 231, 235, 1);'
}));

const AnalysticBox = styled(Grid)(() => ({
  padding: 15,
  minHeight: 114,
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)'
}));

const IconBox = styled(Stack)(() => ({
  padding: 12,
  borderRadius: 12,
  width: 60,
  height: 60,
  justifyContent: 'center',
  alignItems: 'center'
}));

const SubText = styled(Typography)(() => ({
  lineHeight: '12px',
  fontSize: 12,
  paddingTop: '0.75rem',
  textAlign: 'center'
}));

const NumberCount = styled(Typography)(() => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 700
}));

const Icon = styled('img')(() => ({
  width: 36,
  height: 36
}));

const ChartRowItem = styled(Box)(() => ({
  width: 'calc(50% - 8px)'
}));

const MonthTip = styled(Box)(() => ({
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)',
  fontSize: 20,
  lineHeight: '24px',
  textAlign: 'center',
  padding: 20
}));
