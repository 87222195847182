import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import theme from 'styles/theme';
import { TextError } from './TextError';

export type SelectOptions = {
  value: string | number;
  label: string;
  extra?: string | ReactNode;
  extraBefore?: string | ReactNode;
  url?: string;
  color?: string;
};

type InputSelectProps = SelectProps & {
  fontSizeTitle?: string;
  title?: string;
  value?: string | number | null;
  options?: SelectOptions[];
  onChange?: (e: SelectChangeEvent<unknown>) => void;
  onClose?: () => void;
  inputError?: string;
  placeholder?: string;
  defaultValue?: number | string;
  colorTitle?: string;
};
/**
 * @returns Element InputSelect
 */
export const InputSelect: FC<InputSelectProps> = ({
  fontSizeTitle,
  title,
  value,
  options,
  onChange,
  onClose,
  required = false,
  inputError,
  placeholder,
  defaultValue,
  colorTitle,
  ...rest
}) => {
  return (
    <FormControl fullWidth error={!!inputError} required={required}>
      {title && (
        <FormLabel>
          <Typography
            fontSize={fontSizeTitle || '13px'}
            fontWeight={500}
            mb={1}
            color={colorTitle || theme.colors.dark1}>
            {title}
          </Typography>
        </FormLabel>
      )}
      <SelectStyled
        {...rest}
        displayEmpty
        fullWidth
        defaultValue={defaultValue || ''}
        IconComponent={ExpandMoreIcon}
        renderValue={
          !value && placeholder
            ? () => (
                <MenuItem style={{ padding: 0 }} disabled value="">
                  <em>{placeholder}</em>
                </MenuItem>
              )
            : undefined
        }
        value={value}
        variant="outlined"
        onChange={onChange}
        onClose={() => {
          if (onClose) onClose();
        }}>
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options?.map((op: SelectOptions, index: number) => (
          <MenuItemStyled key={index} value={op.value}>
            <Stack
              width={'100%'}
              alignItems={'center'}
              justifyContent={op.extra ? 'space-between' : 'flex-start'}
              flexDirection={'row'}>
              {op.extraBefore && op.extraBefore}
              <TextStyled>{op.label}</TextStyled>
              {op.extra && <Typography width={'max-content'}>{op.extra}</Typography>}
            </Stack>
          </MenuItemStyled>
        ))}
      </SelectStyled>
      {inputError && (
        <FormHelperText error={!!inputError} sx={{ ml: 0, maxWidth: '100%' }}>
          <TextError errorText={inputError} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

const SelectStyled = styled(Select)(() => ({
  minHeight: 56,
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    padding: '16px 12px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    boxShadow: 'inset 0 5px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: 10
  }
}));

const MenuItemStyled = styled(MenuItem)(() => ({
  height: 44
}));
const TextStyled = styled(Typography)(() => ({
  width: 'max-content',
  maxWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
