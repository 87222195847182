import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import theme from 'styles/theme';
import { Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { TextError } from './TextError';
import 'dayjs/locale/en-gb';
interface CustomDatePickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  title?: string;
  datePickerProps?: Partial<DatePickerProps<Dayjs, boolean>>;
  inputError?: string;
  className?: string;
  placeholder?: string;
}

export const CustomDatePicker = ({
  value,
  onChange,
  title,
  datePickerProps = {},
  placeholder,
  inputError
}: CustomDatePickerProps) => {
  return (
    <LocalizationProvider adapterLocale={'en-gb'} dateAdapter={AdapterDayjs}>
      <Stack gap={1}>
        {title && (
          <FormLabel>
            <Typography fontSize={'13px'} fontWeight={500} color={theme.colors.dark1}>
              {title}
            </Typography>
          </FormLabel>
        )}
        <StyledDatePicker
          value={value}
          onChange={onChange}
          label={placeholder}
          slotProps={{
            textField: { inputProps: { placeholder: 'DD/MM/YYYY' } }
          }}
          {...datePickerProps}
        />
        {inputError && (
          <FormHelperText error={!!inputError} sx={{ ml: 0, maxWidth: '100%' }}>
            <TextError errorText={inputError} />
          </FormHelperText>
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

const StyledDatePicker = styled(DatePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    border: 'none',
    color: '#000',
    height: '55px',
    overflow: 'hidden'
  },
  '& .MuiInputBase-root': {
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {}
  },
  '.MuiFormLabel-root.Mui-focused': {
    display: 'none'
  },
  '.MuiFormLabel-root.MuiFormLabel-filled': {
    display: 'none'
  },
  '.MuiFormLabel-root': {
    marginBottom: '8px',
    opacity: '0.6',
    top: '-5px'
  },
  '& fieldset': {
    borderWidth: '1px',
    top: 0,
    '& legend': {
      display: 'none'
    }
  }
}));
