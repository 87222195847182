// @ts-nocheck

import React, { Fragment, useEffect } from 'react';
import { Stack, Typography, styled, Box } from '@mui/material';
import get from 'lodash/get';
import Grid from '@mui/material/Unstable_Grid2';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilledInput } from 'app/components/elements/InputText';
import { Switch } from 'app/components/elements/Switch';
import { useForm, useFieldArray } from 'react-hook-form';
import { SettingForm, useInjection } from './hooks';
import { settingSchema } from 'utils/validate';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { AddIcon, TrashIcon } from 'assets/icons';
import { compact } from 'lodash';
import { InputSelect } from 'app/components/elements/InputSelect';
export function Settings() {
  const { selectedOrganization, handleSaveSettings, defaultForm, initForm, setInitForm, role } =
    useInjection();
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<SettingForm>({
    mode: 'onSubmit',
    resolver: yupResolver(settingSchema),
    defaultValues: defaultForm
  });
  const {
    fields: services,
    append,
    update,
    remove
  } = useFieldArray({
    control,
    name: 'services'
  });
  useEffect(() => {
    if (!initForm) return;
    setInitForm(false);
  }, [selectedOrganization?.id]);

  useEffect(() => {
    if (initForm) return;
    reset(defaultForm);
    setInitForm(true);
  }, [JSON.stringify(defaultForm), initForm]);
  return (
    <form onSubmit={handleSubmit(handleSaveSettings)}>
      <Stack gap={4} style={{ width: '50%', minWidth: '560px' }}>
        <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
          <Typography fontSize={'20px'}>Account Info</Typography>
          <ButtonCustom style={{ width: '25%' }} variant={'contained'} type="submit">
            Save
          </ButtonCustom>
        </Stack>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={6}>
            <FilledInput
              value={selectedOrganization?.id}
              label={'Account ID'}
              variant={'filled'}
              disabled
            />
          </Grid>
          <Grid xs={6}>
            <FilledInput
              inputError={errors?.organizationName?.message}
              inputProps={register('organizationName')}
              label={'Customer Name'}
              variant={'filled'}
            />
          </Grid>
          <Grid xs={6}>
            <FilledInput
              inputError={errors?.username?.message}
              inputProps={register('username')}
              label={'Username'}
              variant={'filled'}
            />
          </Grid>
          {role === 'admin' && (
            <Grid xs={6}>
              <FilledInput
                type={'password'}
                inputError={errors?.password?.message}
                inputProps={register('password')}
                label={'Password'}
                variant={'filled'}
              />
            </Grid>
          )}
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            xs={12}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'20px'}>Interpreter Services</Typography>
            <ButtonCustom
              variant={'contained'}
              style={{ width: '25%' }}
              onClick={(e) => {
                e.preventDefault();
                append(
                  { name: '', data: [{ pinCode: '', activationCode: '' }] },
                  { shouldFocus: false }
                );
              }}>
              <>
                <AddIcon />
                New Service
              </>
            </ButtonCustom>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          {services.map((item, idx) => (
            <Fragment key={idx}>
              <Grid xs={6} pt={4}>
                <StyledSelect
                  onChange={(e) => {
                    e.preventDefault();
                    const service = get(services, `${idx}`);
                    update(idx, {
                      ...service,
                      name: e.target.value as string
                    });
                  }}
                  placeholder="Select service"
                  value={get(services, `${idx}.name`)}
                  options={[{ value: 'languageLine', label: 'LanguageLine' }]}
                />
              </Grid>
              <Grid
                xs={6}
                pt={4}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <Stack>
                  <Typography fontSize={'14px'}>Enable in VidaTalk</Typography>
                  <Switch name={`services.${idx}.enable`} control={control} />
                </Stack>
                <ButtonCustom
                  variant={'outlined'}
                  style={{ width: '50%' }}
                  onClick={(e) => {
                    e.preventDefault();
                    remove(idx);
                  }}>
                  Delete Service
                </ButtonCustom>
              </Grid>
              {get(item, 'data', []).map(
                (row: { activationCode?: string; pinCode?: string }[], index: number) => (
                  <Fragment key={Math.random()}>
                    <Grid xs={6} display={'flex'} direction={'row'} alignItems={'center'}>
                      <TrashIcon
                        onClick={(e) => {
                          e.preventDefault();
                          const codes = get(services, `${idx}.data`, []);
                          codes[index] = null;
                          update(idx, {
                            ...codes,
                            data: compact(codes)
                          });
                        }}
                      />
                      <Box ml={3} width={'100%'}>
                        <FilledInput
                          inputError={
                            (get(
                              errors,
                              `services.${idx}.data.${index}.activationCode.message`
                            ) as string) || ''
                          }
                          inputProps={register(`services.${idx}.data.${index}.activationCode`)}
                          label={'Activation Code'}
                          variant={'filled'}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <FilledInput
                        inputError={
                          (get(
                            errors,
                            `services.${idx}.data.${index}.pinCode.message`
                          ) as string) || ''
                        }
                        inputProps={register(`services.${idx}.data.${index}.pinCode`)}
                        label={'PIN'}
                        variant={'filled'}
                      />
                    </Grid>
                  </Fragment>
                )
              )}
              <Grid xs={6}>
                <ButtonCustom
                  variant={'outlined'}
                  style={{ width: '30%' }}
                  onClick={(e) => {
                    e.preventDefault();
                    const codes = get(services, `${idx}.data`, []);
                    codes[codes.length] = {
                      activationCode: '',
                      pinCode: ''
                    };
                    update(idx, {
                      ...codes,
                      data: compact(codes)
                    });
                  }}>
                  + PIN
                </ButtonCustom>
              </Grid>
              <Divider xs={12} />
            </Fragment>
          ))}
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>PIN</Typography>
            <Switch name={'pin'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Speech to Text</Typography>
            <Switch name={'speech2T'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Tour</Typography>
            <Switch name={'tour'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Text to Translate</Typography>
            <Switch name={'t2TTranslate'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Logout</Typography>
            <Switch name={'logout'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Sharecare</Typography>
            <Switch name={'sharecare'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Support</Typography>
            <Switch name={'support'} control={control} />
          </Grid>
          <Grid
            xs={6}
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography fontSize={'14px'}>Video Interpreter</Typography>
            <Switch name={'vInterpreter'} control={control} />
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
const Divider = styled(Grid)(() => ({
  borderBottom: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  margin: '12px'
}));

const StyledSelect = styled(InputSelect)(() => ({
  minHeight: 56,
  maxWidth: 540,
  '& .MuiInputBase-input': {
    backgroundColor: '#E6E0E9',
    padding: '16px 12px',
    borderRadius: '10px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    borderRadius: 10
  }
}));
