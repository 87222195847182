import { LoginPage } from 'app/pages/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LayoutAdmin } from 'app/components/templates/LayoutAdmin';
import { RequireAdminAuth } from 'app/components/modules/AuthRouter';
import { HelmetProvider } from 'react-helmet-async';
import { ReportPage } from 'app/pages/ReportPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { ForgotPasswordPage } from 'app/pages/ForgotPasswordPage';
import { AccountPage } from 'app/pages/AccountPage';
import { UsersPage } from 'app/pages/UsersPage';

export default function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route
          path="*"
          errorElement={<NotFoundPage />}
          element={
            <RequireAdminAuth>
              <LayoutAdmin>
                <Routes>
                  <Route path="reports" element={<ReportPage />} />
                  <Route path="enterprise-accounts" element={<AccountPage />} />
                  <Route path="individual-accounts" element={<AccountPage />} />
                  <Route path="users" element={<UsersPage />} />
                  <Route path="*" element={<Navigate to="reports" />} />
                </Routes>
              </LayoutAdmin>
            </RequireAdminAuth>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      </Routes>
    </HelmetProvider>
  );
}
