/* eslint-disable @typescript-eslint/no-explicit-any */

import * as TasksApi from 'services/task';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import keyCrossRef from '../../../../../data/keyCrossRef.json';
import cardsToEmotion from '../../../../../data/cardsToEmotion.json';
import langToLanguage from '../../../../../data/langToLanguage.json';
import { isNil, omitBy } from 'lodash';
import { useOrganizations } from 'contexts/organizations';

const gett2t = async (params: any) => TasksApi.getTalk2TextFreq(params);
const gett2s = async (params: any) => TasksApi.getText2SpeechFreq(params);
const gett2trans = async (params: any) => TasksApi.getText2TranslateFreq(params);
const getCommonExchanges = async (params: any) => TasksApi.getCommonExchanges(params);
const getLanguagesSpoken = async (params: any) => TasksApi.getLanguagesSpoken(params);
const getLanguagesTranslated = async (params: any) => TasksApi.getLanguagesTranslated(params);
const getConversations = async (params: any) => TasksApi.getConversations(params);
const getAnnualConversations = async (params: any) => TasksApi.getAnnualConversations(params);

let thisMonth = 0;
export const useInjection = () => {
  const [params, setUrlParams] = useSearchParams();

  let newParams = {
    from: '',
    to: '',
    act1: '',
    act2: ''
  };
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const { selectedOrganization } = useOrganizations();
  const [loading, setLoading] = useState(false);
  const [talk2TextFreq, setTalk2TextFreq] = useState<any[]>([]);
  const [text2SpeechFreq, setText2SpeechFreq] = useState<any[]>([]);
  const [text2TranslateFreq, setText2TranslateFreq] = useState<any[]>([]);
  const [commonExchanges, setCommonExchanges] = useState<any[]>([]);
  const [languagesSpoken, setLanguagesSpoken] = useState<any[]>([]);
  const [languagesTranslated, setLanguagesTranslated] = useState<any[]>([]);
  const [conversations, setConversations] = useState<any[]>([]);
  const [annualConversations, setAnnualConversations] = useState<any[]>([]);

  const onFilter = useCallback(() => {
    const qs = {
      from: startDate?.format('YYYY-MM-DD'),
      to: endDate?.format('YYYY-MM-DD'),
      act1: selectedOrganization?.id,
      tab: 'reports'
    };
    setUrlParams(omitBy(qs, isNil) as any);
  }, [startDate, endDate, selectedOrganization]);

  // @ts-ignore
  const fetchData = useCallback(async (params) => {
    setLoading(true);
    try {
      const res = await gett2t(params);
      res.map((x: any) => {
        setTalk2TextFreq(x.count);
      });
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await gett2s(params);
      res.map((x: any) => {
        setText2SpeechFreq(x.count);
      });
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await gett2trans(params);
      res.map((x: any) => {
        setText2TranslateFreq(x.count);
      });
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getCommonExchanges(params);
      setCommonExchanges(res);
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getLanguagesSpoken(params);
      setLanguagesSpoken(res);
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getLanguagesTranslated(params);
      setLanguagesTranslated(res);
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await getConversations(params);
      setConversations(res);
    } catch (err) {
      console.log(err);
    }
    try {
      newParams = params;
      Object.keys(newParams).forEach((key) => {
        if (key === 'to') {
          thisMonth = Number(params[key].split('-')[1] - 1);
          // change 'to' to one month ago
          const to = new Date(newParams[key]);
          const newTo = to.setMonth(to.getMonth() - 1);
          dayjs(newTo).format('YYYY-MM-DD');
          newParams[key] = dayjs(newTo).format('YYYY-MM-DD').toString();
        }
        if (key === 'from') {
          // change from to 13 months ago
          const from = new Date(newParams[key]);
          const newFrom = from.setMonth(from.getMonth() - 12);
          dayjs(newFrom).format('YYYY-MM-DD');
          newParams[key] = dayjs(newFrom).format('YYYY-MM-DD').toString();
        }
      });
      // console.log(newParams);
      const res = await getAnnualConversations(newParams);
      setAnnualConversations(res);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (params.get('from')) {
      fetchData(params);
    }
  }, [params]);

  const replaceEmotion: any[] = [];
  const replacePhrases: any[] = [];
  const replaceLanguage: any[] = [];
  const replaceTranslatedLanguage: any[] = [];

  // replace keys with phrases spoken
  /* eslint-disable array-callback-return */
  commonExchanges.map((x) => {
    keyCrossRef.forEach((d) => {
      if (d.code === x.dataKey) {
        const myObject = { text: d.means, value: Number(x.value) };
        replacePhrases.push(myObject);
      }
    });
  });

  // apply emotions to common exchanges
  /* eslint-disable array-callback-return */
  commonExchanges.map((x) => {
    cardsToEmotion.forEach((d) => {
      if (d.code === x.dataKey) {
        const myObject = { text: d.means, value: Number(x.value) };
        replaceEmotion.push(myObject);
      }
    });
  });

  // reduce the phrases to a list of top emotions
  const emotions = replaceEmotion.reduce((r, { text, value }) => {
    const temp = r.find((o: { text: any }) => o.text === text);
    if (temp) {
      temp.value += value;
    } else {
      r.push({ text, value });
    }
    return r;
  }, []);

  emotions.sort((a: { value: number }, b: { value: number }) => b.value - a.value);

  /* eslint-disable array-callback-return */
  languagesSpoken.map((x) => {
    langToLanguage.forEach((d) => {
      if (d.code === x.language) {
        const myObject = { language: d.means, count: Number(x.count) };
        replaceLanguage.push(myObject);
      }
    });
  });

  /* eslint-disable array-callback-return */
  languagesTranslated.map((x) => {
    langToLanguage.forEach((d) => {
      // remove en if exists
      if (d.code === x.language && d.code !== 'en') {
        const myObject = { language: d.means, count: Number(x.count) };
        replaceTranslatedLanguage.push(myObject);
      }
    });
  });

  // create object of objects that total events each day of the month
  const dailyConversationsConverted: any[] = [];
  let i = 0;
  while (i < 32) {
    // populate the month with 0 in each day
    dailyConversationsConverted.push([i + 1, 0]);
    /* eslint-disable no-plusplus */
    i++;
  }
  conversations.reduce((res: any, value) => {
    // update each day that has conversations
    const onlyDate = Number(value.createdDate.split('-')[2].substring(0, 2));
    if (!res[onlyDate as keyof typeof res]) {
      res[onlyDate] = [onlyDate, 0];
      dailyConversationsConverted[onlyDate] = res[onlyDate];
    }
    res[onlyDate][1] += Number(value.startEvent);
    return res;
  }, {});

  // create object of objects that total events each day of the month
  const monthlyConversationsConverted: any[] = [];
  let y = 0;
  while (y < 13) {
    // populate the month with 0 in each month
    monthlyConversationsConverted.push([y, 0]);
    /* eslint-disable no-plusplus */
    y++;
  }

  annualConversations.reduce((res: any, value) => {
    // update each day that has conversations
    const onlyDate = Number(value.createdDate.split('-')[1]);
    if (!res[onlyDate as keyof typeof res]) {
      res[onlyDate] = [onlyDate, 0];
      monthlyConversationsConverted[onlyDate] = res[onlyDate];
    }
    res[onlyDate][1] += Number(value.startEvent);
    return res;
  }, {});

  // remove the leading 0 in these results.
  monthlyConversationsConverted.shift();
  dailyConversationsConverted.shift();

  // create a new array to hold the correct months in order

  // find the first month to put in the first element
  // it is the month that is 12 months ago
  // so if the report date is 2/1/2024
  // then the first element in the chart should be for
  // January 2023

  const finalMonthOrder: any[] = [];

  // console.log('monthly converted', monthlyConversationsConverted);

  finalMonthOrder[0] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(13, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[1] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(12, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[2] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(11, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[3] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(10, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[4] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(9, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[5] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(8, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[6] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(7, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[7] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(6, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[8] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(5, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[9] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(4, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[10] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(3, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];
  finalMonthOrder[11] =
    monthlyConversationsConverted[
      Number(dayjs().month(thisMonth).subtract(2, 'months').format('MM'))
    ] || monthlyConversationsConverted[0];

  const reallyFinalMonthOrder: any[] = [];
  for (i = 0; i < 11; i++) {
    reallyFinalMonthOrder[i] = [i, finalMonthOrder[i][1]];
  }

  return {
    thisMonth,
    startDate,
    endDate,
    loading,
    talk2TextFreq,
    text2SpeechFreq,
    text2TranslateFreq,
    conversations,
    replacePhrases,
    emotions,
    replaceLanguage,
    replaceTranslatedLanguage,
    dailyConversationsConverted,
    reallyFinalMonthOrder,
    setStartDate,
    setEndDate,
    onFilter: onFilter
  };
};
