import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { getDashboardContent, updateDashboardContent } from 'services/admin';
import { useEffect, useState } from 'react';
import { useFlash } from 'contexts/flash';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();

type DashboardSetting = {
  id: number;
  content: string;
  created: string;
  updated: string;
};
export const useInjection = () => {
  const { setFlash } = useFlash();
  const role = storage.getRole();

  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState('');
  const { asyncCallback } = useAsyncCallback(async () => {
    const res: DashboardSetting = await getDashboardContent();
    setContent(res?.content);
  }, []);

  const { asyncCallback: onSave } = useAsyncCallback(async () => {
    if (!isEdit) {
      setIsEdit(true);
      return;
    }
    await updateDashboardContent(content);
    setIsEdit(false);
    setFlash({ type: 'success', message: 'Update content successfully!' });
  }, [content, isEdit]);

  useEffect(() => {
    asyncCallback();
  }, []);
  return { isAdmin: role === 'admin', content, setContent, isEdit, setIsEdit, onSave };
};
