import React, { useCallback, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FilledInput } from 'app/components/elements/InputText';
import { AddIcon } from 'assets/icons';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { PortalDialog } from 'app/components/modules/PortalDialog';
import { CreateOrganizationForm } from 'app/components/modules/CreateOrganizationForm';
import { ContractForm, useInjection } from './hooks';
import CustomDatePicker from 'app/components/elements/InputDate';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contractSchema } from 'utils/validate';
export function Account() {
  const {
    role,
    contract,
    selectedOrganization,
    openNewAccount,
    setOpenNewAccount,
    handleCreateOrganization,
    handleUpdateContract,
    defaultForm
  } = useInjection();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<ContractForm>({
    resolver: yupResolver(contractSchema),
    defaultValues: defaultForm
  });

  useEffect(() => {
    if (!defaultForm.limitLicense) return;
    reset(defaultForm);
  }, [JSON.stringify(defaultForm)]);

  const contractEndDate = watch('contractEndDate');
  const renderAccountInfo = useCallback(() => {
    if (selectedOrganization?.parentOrganizationId)
      return (
        <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
          <Typography sx={{ width: '25%' }}>Sub Account</Typography>
          <FilledInput
            sx={{ width: '70%' }}
            value={selectedOrganization?.name}
            label={'Sub account'}
            variant={'filled'}
            disabled
          />
        </Stack>
      );
    return (
      <>
        <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
          <Typography sx={{ width: '25%' }}>Master Account</Typography>
          <FilledInput
            sx={{ width: '70%' }}
            label={'Master account'}
            value={selectedOrganization?.name}
            variant={'filled'}
            disabled
          />
        </Stack>
        {selectedOrganization?.childrenOrganizations?.map((item) => (
          <Stack
            direction={'row'}
            gap={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            key={item.id}>
            <Typography sx={{ width: '25%' }}>Sub Account</Typography>
            <FilledInput
              sx={{ width: '70%' }}
              value={item.name}
              label={'Sub account'}
              variant={'filled'}
              disabled
            />
          </Stack>
        ))}
        <Stack alignItems={'flex-end'}>
          <ButtonCustom
            variant={'contained'}
            style={{ width: '25%' }}
            onClick={() => setOpenNewAccount(true)}>
            <>
              <AddIcon />
              New Account
            </>
          </ButtonCustom>
        </Stack>
      </>
    );
  }, [selectedOrganization]);
  return (
    <>
      <Stack gap={4} style={{ width: '50%', minWidth: '560px' }}>
        {renderAccountInfo()}
        <Typography fontWeight={700} fontSize={'20px'}>
          Contract Details
        </Typography>
        <form onSubmit={handleSubmit(handleUpdateContract)}>
          <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={{ width: '50%' }}>Total number of licenses</Typography>
            <Box sx={{ width: '40%' }}>
              <FilledInput
                inputProps={register('limitLicense')}
                inputError={errors?.limitLicense?.message}
                variant={'filled'}
                disabled={role !== 'admin'}
              />
            </Box>
          </Stack>
          <Stack
            pt={2}
            direction={'row'}
            gap={2}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography sx={{ width: '50%' }}>Total number of licenses in use</Typography>
            <Typography fontWeight={700} fontSize={'20px'}>
              {contract?.licensesInUse}
            </Typography>
          </Stack>
          {!selectedOrganization?.parentOrganizationId && (
            <Stack
              py={2}
              direction={'row'}
              gap={2}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography sx={{ width: '50%' }}>Total number of sub accounts</Typography>
              <Typography fontWeight={700} fontSize={'20px'}>
                {contract?.numberOfSubAccounts}
              </Typography>
            </Stack>
          )}
          <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={{ width: '50%' }}>Contract end date</Typography>
            <CustomDatePicker
              inputError={errors?.contractEndDate?.message}
              datePickerProps={{ disabled: role !== 'admin' }}
              value={dayjs(contractEndDate)}
              onChange={(val) => {
                if (!val) return;
                setValue('contractEndDate', val.toISOString());
              }}
            />
          </Stack>
          {role === 'admin' && (
            <Stack alignItems={'flex-end'} py={2} px={1}>
              <ButtonCustom style={{ width: '25%' }} variant={'contained'} type="submit">
                Save
              </ButtonCustom>
            </Stack>
          )}
        </form>
      </Stack>
      <PortalDialog
        title={'Create New Account'}
        isOpen={openNewAccount}
        onClose={() => setOpenNewAccount(false)}>
        <CreateOrganizationForm onSubmit={handleCreateOrganization} />
      </PortalDialog>
    </>
  );
}
