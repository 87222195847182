import React, { memo, useCallback } from 'react';

import WordCloud from 'react-d3-cloud';

// word cloud cannot do server side rendering.  So turn it off for this component.
import { Board } from '../Board';
/* eslint-disable @typescript-eslint/no-explicit-any */
const WordCloudObj = ({ projects, loading, heading }: any) => {
  // @ts-ignore
  const fontSize = useCallback((word: { value: number }) => Math.log2(word.value) * 8, []);
  const rotate = useCallback((word: { value: number }) => word.value % 1, []);
  const onWordClick = useCallback((word: unknown) => {
    console.log('onWordClick:', word);
  }, []);
  const onWordMouseOver = useCallback((word: { srcElement: { innerHTML: unknown } }) => {
    // @ts-ignore
    const obj = projects.find((o: { text: string }) => o.text === word.srcElement.innerHTML);
    console.log('onWordMouseOver:', obj?.value);
    // show tooltip
  }, []);
  const onWordMouseOut = useCallback((word: unknown) => {
    console.log('onWordMouseOut:', word);
  }, []);

  return (
    <Board loading={loading} heading={heading}>
      <div>
        {typeof window !== 'undefined' && (
          <WordCloud
            data={projects}
            width={400}
            height={200}
            font="Times"
            fontStyle="italic"
            fontWeight="bold"
            fontSize={fontSize}
            // spiral="archimedean"
            rotate={rotate}
            // padding={2}
            random={Math.random}
            onWordClick={onWordClick}
            onWordMouseOver={onWordMouseOver}
            onWordMouseOut={onWordMouseOut}
          />
        )}
      </div>
    </Board>
  );
};
export const WordCloudChart = memo(WordCloudObj, (prevProps, nextProps) => {
  if (prevProps?.projects.length && nextProps?.projects.length) {
    return JSON.stringify(prevProps.projects) !== JSON.stringify(nextProps.projects);
  }
  return false;
});
