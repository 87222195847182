import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { PAGE_SIZE, useInjection } from './hooks';
import { DataGrid } from '@mui/x-data-grid';
import { FilledInput } from 'app/components/elements/InputText';
import InputAdornment from '@mui/material/InputAdornment';
import { ExportIcon, MenuIcon, SearchIcon } from 'assets/icons';
import { PortalDialog } from 'app/components/modules/PortalDialog';
import UserDetail from 'app/components/modules/UserDetail';

export const UsersPage = () => {
  const {
    users,
    columns,
    total,
    page,
    onChangePage,
    loading,
    keyword,
    onChangeKeyword,
    onSearch,
    onExportUsers,
    selectedUser,
    setSelectedUser
  } = useInjection();
  return (
    <>
      <Stack direction={'row'} sx={{ overflowY: 'hidden', height: 'inherit' }}>
        <Wrapper>
          <TitleStyled>VidaTalk User List</TitleStyled>
          <DescriptionStyled>
            Full list of users with access to VidaTalk, including individual, admin, and enterprise
            account users
          </DescriptionStyled>
          <Stack direction={'row'} py={2} alignItems={'center'} justifyContent={'space-between'}>
            <SearchInput
              value={keyword}
              onChange={(event) => onChangeKeyword(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onSearch();
                }
              }}
              placeholder={'Search'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MenuIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={onSearch} style={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }}
            />
            <ExportIcon style={{ cursor: 'pointer' }} onClick={onExportUsers} />
          </Stack>
          <Stack direction={'column'}>
            <DataGrid
              rows={users}
              columns={columns}
              rowCount={total}
              paginationModel={{
                page: page ? Number(page) - 1 : 1,
                pageSize: PAGE_SIZE
              }}
              onPaginationModelChange={(newPaginationModel) =>
                onChangePage(newPaginationModel.page + 1)
              }
              onRowClick={({ row }) => setSelectedUser(row)}
              loading={loading}
              checkboxSelection={false}
              disableAutosize
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableColumnSorting
              disableDensitySelector
              paginationMode="server"
              sx={{ border: 0 }}
            />
          </Stack>
        </Wrapper>
      </Stack>
      <PortalDialog
        title={'User Details'}
        isOpen={!!selectedUser?.id}
        onClose={() => setSelectedUser(null)}>
        {selectedUser ? (
          <UserDetail
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
            onChangeStatus={() => {}}
          />
        ) : (
          <></>
        )}
      </PortalDialog>
    </>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  padding: 32,
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)'
}));
const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '29px',
  paddingBottom: 12
}));
const DescriptionStyled = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '22px',
  fontStyle: 'italic'
}));
const SearchInput = styled(FilledInput)(() => ({
  width: 360,
  borderRadius: '28px',
  border: 'none',
  backgroundColor: '#E6E0E9',
  color: '#1D1B20',
  '& .MuiInputBase-input': {
    borderRadius: '28px',
    border: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '28px',
    border: 'none'
  }
}));
