import { useEffect, useState } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useFlash } from 'contexts/flash';
import { uploadQrCode } from 'services/organization';
import { useOrganizations } from 'contexts/organizations';

const API_URL = process.env.REACT_APP_API_URL || '';
export const useInjection = () => {
  const { setFlash } = useFlash();
  const { selectedOrganization } = useOrganizations();
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (!selectedOrganization?.qrCodeId) {
      setUrl('');
      return;
    }
    const urlStr = `${API_URL}/storages/qr-code/${selectedOrganization?.qrCodeId}`;
    if (urlStr !== url) setUrl(urlStr);
  }, [selectedOrganization]);
  const [file, setFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { asyncCallback: handleUploadQR, loadingCb } = useAsyncCallback(async () => {
    if (!selectedOrganization || !file) return;
    const res = await uploadQrCode(selectedOrganization.id, file);
    if (res.url) {
      setUrl(res.url);
      setOpenModal(false);
      setFlash({ type: 'success', message: 'Upload QR Code successfully!' });
    }
  }, [selectedOrganization, file]);

  return {
    openModal,
    setOpenModal,
    file,
    selectedOrganization,
    onChangeFile: (val: File) => setFile(val),
    handleUploadQR,
    loadingCb,
    url
  };
};
