import { Box, styled } from '@mui/material';

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type BoardProps = {
  loading: boolean;
  heading?: string;
  children: React.ReactElement;
};
export function Board({ loading, heading, children }: BoardProps) {
  return (
    <BoardContainer>
      <Heading>{heading}</Heading>
      <Box p={4}>{loading ? <CircularProgress /> : children}</Box>
    </BoardContainer>
  );
}

const BoardContainer = styled(Box)(() => ({
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)'
}));

const Heading = styled(Box)(() => ({
  lineHeight: '20px',
  fontWeight: 700,
  fontSize: 18,
  padding: 24,
  borderBottom: '1px solid rgba(229, 231, 235, 1);'
}));
