// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  padding: 0;
  margin: 0;
}

textarea {
  font-family: inherit;
  text-transform: unset;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiButtonBase-root {
  text-transform: inherit;
}
button {
  text-transform: inherit;
}

p {
  text-transform: initial;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #dcdbe0;
  border-radius: 5px;
}

.pac-container {
  z-index: 99999;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["html,\nbody {\n  padding: 0;\n  margin: 0;\n}\n\ntextarea {\n  font-family: inherit;\n  text-transform: unset;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n.MuiButtonBase-root {\n  text-transform: inherit;\n}\nbutton {\n  text-transform: inherit;\n}\n\np {\n  text-transform: initial;\n}\n\n* {\n  box-sizing: border-box;\n}\n::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n::-webkit-scrollbar-thumb {\n  background: #dcdbe0;\n  border-radius: 5px;\n}\n\n.pac-container {\n  z-index: 99999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
