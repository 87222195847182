import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useInjection } from './hooks';
import InfiniteScroll from 'react-infinite-scroll-component';

function AccountList() {
  const { role, fetchMore, total, organizations, selectedOrganization, setSelectedOrganization } =
    useInjection();
  return (
    <Wrapper id={'scrollableDiv'}>
      <Title sx={{ cursor: 'default' }}>Accounts</Title>
      <InfiniteScroll
        dataLength={organizations.length}
        next={fetchMore}
        hasMore={total !== organizations.length}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv">
        {organizations.map((organization) => (
          <div key={organization.id}>
            <Title
              key={organization.id}
              role={role}
              onClick={() => role !== 'subEnterprise' && setSelectedOrganization(organization)}
              fontWeight={500}
              style={selectedOrganization?.id === organization.id ? { background: '#E8DEF8' } : {}}>
              {organization.name}
            </Title>
            {organization?.childrenOrganizations?.map((child) => (
              <Title
                key={child.id}
                onClick={() => setSelectedOrganization(child)}
                fontWeight={500}
                marginLeft={2}
                style={selectedOrganization?.id === child.id ? { background: '#E8DEF8' } : {}}>
                {child.name}
              </Title>
            ))}
          </div>
        ))}
      </InfiniteScroll>
    </Wrapper>
  );
}

export default AccountList;

const Wrapper = styled(Box)(() => ({
  backgroundColor: '#F7F2FA',
  borderRadius: '16px',
  padding: 12,
  width: 314,
  height: 'calc(100vh - 70px)',
  overflowY: 'scroll'
}));

const Title = styled(Typography)(({ role }: { role?: string }) => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#49454F',
  padding: '18px 16px',
  borderRadius: '100px',
  cursor: role === 'subEnterprise' ? 'not-allowed' : 'pointer'
}));
