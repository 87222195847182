import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { SubmitButton, SubmitButtonProps } from 'app/components/elements/SubmitButton';
import { CircularProgress } from '@mui/material';

type CustomButtonProps = {
  label?: string;
  loading?: boolean;
} & ButtonProps &
  SubmitButtonProps;

/**
 * @returns Element PrimaryButton
 */
export const ButtonCustom: FC<CustomButtonProps> = ({ label, loading, ...props }) => {
  return (
    <PrimaryButtonStyled {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {label && (
            <Typography fontSize={16} fontWeight={600}>
              {label}
            </Typography>
          )}
          {props.children}
        </>
      )}
    </PrimaryButtonStyled>
  );
};

const PrimaryButtonStyled = styled(SubmitButton)(() => ({
  borderRadius: 10,
  textTransform: 'none',
  width: '100%',
  height: 56
}));
