import React, { Fragment, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enterpriseAdminSchema } from 'utils/validate';
import { Box, Stack, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddIcon, TrashIcon } from 'assets/icons';
import { FilledInput } from 'app/components/elements/InputText';
import get from 'lodash/get';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { SecurityForm, useInjection } from './hooks';
import { RadioButton } from 'app/components/elements/RadioButton';
import { InputSelect } from 'app/components/elements/InputSelect';

export const Security = () => {
  const { defaultForm, subOrganizations, onSave, selectedOrganization, isSubAccount } =
    useInjection();
  const {
    control,
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors }
  } = useForm<{ accounts: SecurityForm }>({
    resolver: yupResolver(enterpriseAdminSchema),
    defaultValues: defaultForm
  });
  const {
    fields: accounts,
    append,
    update,
    remove
  } = useFieldArray({
    control,
    keyName: 'rowId',
    name: 'accounts'
  });
  useEffect(() => {
    reset(defaultForm);
  }, [JSON.stringify(defaultForm), selectedOrganization?.id]);
  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Stack direction={'row'} sx={{ overflowY: 'hidden', height: 'inherit' }}>
        <Wrapper width={isSubAccount ? '50%' : '100%'}>
          <Stack direction={'column'} py={4}>
            <TableHeaders container>
              <Grid xs={isSubAccount ? 1 : 0.5} />
              <Grid xs={isSubAccount ? 5 : 2}>
                <HeaderColum>Name</HeaderColum>
              </Grid>
              <Grid xs={isSubAccount ? 6 : 3}>
                <HeaderColum>Email</HeaderColum>
              </Grid>
              {!isSubAccount && (
                <>
                  <Grid xs={2} display={'flex'} justifyContent={'center'}>
                    <HeaderColum>Enterprise Admin</HeaderColum>
                  </Grid>
                  <Grid xs={2} display={'flex'} justifyContent={'center'}>
                    <HeaderColum>Sub Account Admin</HeaderColum>
                  </Grid>
                  <Grid xs={2.5}>
                    <HeaderColum>Account</HeaderColum>
                  </Grid>
                </>
              )}
            </TableHeaders>
            {accounts.map((admin, index) => (
              <Fragment key={index}>
                <Grid container pt={'8px'} py={'8px'}>
                  <Grid
                    xs={isSubAccount ? 1 : 0.5}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <TrashIcon onClick={() => remove(index)} />
                  </Grid>
                  <Grid xs={isSubAccount ? 5 : 2} pr={2}>
                    <FilledInput
                      inputProps={register(`accounts.${index}.name`)}
                      inputError={(get(errors, `accounts.${index}.name.message`) as string) || ''}
                      variant={'filled'}
                    />
                  </Grid>
                  <Grid xs={isSubAccount ? 6 : 3}>
                    <FilledInput
                      inputProps={register(`accounts.${index}.email`)}
                      inputError={(get(errors, `accounts.${index}.email.message`) as string) || ''}
                      variant={'filled'}
                      disabled={!!admin?.id}
                    />
                  </Grid>
                  {!isSubAccount && (
                    <>
                      <Grid xs={2} display={'flex'} justifyContent={'center'}>
                        <RadioButton
                          checked={admin.role === 'enterprise'}
                          value={'enterprise'}
                          onChange={(val) => {
                            const item = getValues('accounts')[index];
                            update(index, {
                              ...item,
                              organizationId: selectedOrganization?.id,
                              role: val.target.checked ? 'enterprise' : 'subEnterprise'
                            });
                          }}
                        />
                      </Grid>
                      <Grid xs={2} display={'flex'} justifyContent={'center'}>
                        <RadioButton
                          checked={admin.role === 'subEnterprise'}
                          value={'subEnterprise'}
                          onChange={(val) => {
                            const item = getValues('accounts')[index];
                            update(index, {
                              ...item,
                              role: val.target.checked ? 'subEnterprise' : 'enterprise'
                            });
                          }}
                        />
                      </Grid>
                      <Grid xs={2.5}>
                        <StyledSelect
                          onChange={(e) => {
                            const item = getValues('accounts')[index];
                            update(index, {
                              ...item,
                              organizationId: e.target.value as number
                            });
                          }}
                          disabled={admin.role === 'enterprise'}
                          value={admin.organizationId}
                          options={subOrganizations}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Fragment>
            ))}
            <TableHeaders container style={{ padding: '24px 0' }}>
              <Grid xs={0.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <AddIcon
                  onClick={() => {
                    append({
                      name: '',
                      email: '',
                      role: isSubAccount ? 'subEnterprise' : 'enterprise',
                      organizationId: selectedOrganization?.id
                    });
                  }}
                />
              </Grid>
            </TableHeaders>
          </Stack>
          <Stack justifyContent={'flex-end'} pt={2} px={1} direction={'row'}>
            <ButtonCustom variant={'contained'} style={{ width: '15%' }} type="submit">
              Save
            </ButtonCustom>
          </Stack>
        </Wrapper>
      </Stack>
    </form>
  );
};

const Wrapper = styled(Box)(() => ({
  overflowY: 'scroll',
  flexDirection: 'row',
  maxHeight: 'calc(100vh - 70px)',
  minWidth: 592
}));

const TableHeaders = styled(Grid)(() => ({
  borderBottom: '1px solid #CAC4D0',
  paddingBottom: 8
}));

const HeaderColum = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#999999'
}));

const StyledSelect = styled(InputSelect)(() => ({
  minHeight: 56,
  maxWidth: 540,
  '& .MuiInputBase-input': {
    backgroundColor: '#E6E0E9',
    padding: '16px 12px',
    borderRadius: '10px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    borderRadius: 10
  }
}));
