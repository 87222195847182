import ReactDOM from 'react-dom/client';

import { configureAxios } from 'config/axios';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import 'styles/global.css';
import App from 'app';
import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from 'contexts/loading';
import { FlashProvider } from 'contexts/flash';
import { Flash } from 'app/components/elements/Flash';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import theme from 'styles/theme';
import { OrganizationsProvider } from 'contexts/organizations';

// Initialize axios
configureAxios();

/**
 * Root App
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LoadingProvider>
          <FlashProvider>
            <CssBaseline />
            <OrganizationsProvider>
              <App />
            </OrganizationsProvider>
            <Flash />
          </FlashProvider>
        </LoadingProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);
