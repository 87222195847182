import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type PortalModalProps = {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactElement;
};
export function PortalDialog({ title, isOpen, onClose, children }: PortalModalProps) {
  return (
    <React.Fragment>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        {title && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {title}
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ marginTop: title ? '' : '54px' }} dividers>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
