import React from 'react';
import { Stack } from '@mui/material';
import { FilledInput } from '../../elements/InputText';
import { InputPassword } from '../../elements/InputPassword';
import { ButtonCustom } from '../../elements/ButtonCustom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createOrganizationSchema } from 'utils/validate';
export type OrganizationForm = {
  organizationName: string;
  limitLicense: number;
  username: string;
  password: string;
};
type CreateOrganizationFormProps = {
  onSubmit: (val: OrganizationForm) => void;
};
export function CreateOrganizationForm({ onSubmit }: CreateOrganizationFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<OrganizationForm>({ resolver: yupResolver(createOrganizationSchema) });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} sx={{ width: 540 }}>
        <FilledInput
          inputProps={register('organizationName')}
          label={'Organization name'}
          variant={'filled'}
          required
        />
        <FilledInput
          inputProps={register('limitLicense')}
          label={'Limit licenses'}
          defaultValue={10}
          type="number"
          variant={'filled'}
        />
        <FilledInput
          inputError={errors?.username?.message}
          inputProps={register('username')}
          label={'Account name'}
          variant={'filled'}
        />
        <InputPassword inputError={errors?.password?.message} inputProps={register('password')} />
      </Stack>
      <Stack alignItems={'flex-end'} pt={2} px={1}>
        <ButtonCustom variant={'contained'} style={{ width: '25%' }} type="submit">
          Create
        </ButtonCustom>
      </Stack>
    </form>
  );
}
