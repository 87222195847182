import axios from 'axios';

export const listMyOrganizations = async () => {
  const { data } = await axios.get('/organizations/mine');
  return data;
};

export const updateMyOrganization = async (id: number, payload: unknown) => {
  const { data } = await axios.put(`/organizations/${id}/settings`, payload);
  return data;
};
