import { IconButton, InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

import { InputText } from 'app/components/elements/InputText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { adminLoginSchema, enterpriseLoginSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserIcon } from 'assets/icons';

export type FormLoginType = {
  account: string;
  password: string;
};

type FormLoginProps = {
  loginRole: string;
  onSubmit: (data: FormLoginType) => void;
};
export const FormLogin = ({ onSubmit, loginRole }: FormLoginProps) => {
  const schema = loginRole === 'admin' ? adminLoginSchema : enterpriseLoginSchema;
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm<FormLoginType>({ resolver: yupResolver(schema) });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputText
            inputError={errors.account?.message}
            placeholder={'Email'}
            inputProps={{
              ...register('account')
            }}
            onChange={(e) => {
              clearErrors('account');
              setValue('account', e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
                <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                  <UserIcon />
                </Stack>
              </InputAdornment>
            }
          />
        </Stack>
        <Stack gap={1}>
          <InputText
            placeholder={'Password'}
            inputError={errors.password?.message}
            inputProps={{
              ...register('password'),
              type: showPassword ? 'text' : 'password'
            }}
            onChange={(e) => {
              clearErrors('password');
              setValue('password', e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <Visibility sx={{ color: '#22374A' }} />
                  ) : (
                    <VisibilityOff sx={{ color: '#22374A' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Stack>
        <ButtonCustom variant={'contained'} type={'submit'} label={'Log In'} />
      </Stack>
    </form>
  );
};
