import { useOrganizations } from 'contexts/organizations';
import { useCallback, useEffect, useState } from 'react';
import { listOrganization } from 'services/admin';
import { StorageServices } from 'services/storage';
import { listMyOrganizations } from 'services/organization';
import { unionBy } from 'lodash';
const storage = new StorageServices();

export const useInjection = () => {
  const role = storage.getRole();
  const { selectedOrganization, setOrganizations, setSelectedOrganization, organizations } =
    useOrganizations();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const fetchOrganizations = useCallback(
    async (page = 1) => {
      if (role === 'admin') {
        const res = await listOrganization({ isMasterOrganization: true, page, size: 20 });
        setTotal(res.total);
        const raw = unionBy(organizations, res.organizations, 'id');
        setOrganizations(raw);
        if (selectedOrganization) return;
        setSelectedOrganization(raw[0]);
        return;
      }
      const res = await listMyOrganizations();
      setOrganizations([res]);
      setSelectedOrganization(role === 'enterprise' ? res : res?.childrenOrganizations[0]);
    },
    [organizations, selectedOrganization]
  );

  const fetchMore = useCallback(() => {
    fetchOrganizations(page + 1);
    setPage(page + 1);
  }, [page, organizations, selectedOrganization]);
  useEffect(() => {
    if (!role) return;
    fetchOrganizations();
  }, [role]);

  return {
    role,
    setPage,
    fetchMore,
    total,
    organizations,
    selectedOrganization,
    setSelectedOrganization
  };
};
