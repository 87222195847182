import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, styled } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FormLogin } from 'app/pages/LoginPage/components/FormLogin';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { Helmet } from 'react-helmet-async';
import { useInjection } from './hook';

export const LoginPage = () => {
  const { handleSubmitForm, loginRole, setLoginRole } = useInjection();

  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Vidatalk - Login</title>
      </Helmet>
      <LayoutContent title={'Welcome Back!'}>
        <ToggleButtonGroup
          sx={{ padding: '18px 0' }}
          value={loginRole}
          exclusive
          color="primary"
          onChange={(_, value) => {
            if (!value) return;
            setLoginRole(value);
          }}
          aria-label="Login">
          <ToggleButton sx={{ width: '50%' }} value="admin">
            Admin
          </ToggleButton>
          <ToggleButton sx={{ width: '50%' }} value="enterprise">
            Enterprise
          </ToggleButton>
        </ToggleButtonGroup>
        <FormLogin onSubmit={handleSubmitForm} />
        <ForgotLink to={`/forgot-password?role=${loginRole}`}>Forgot password?</ForgotLink>
      </LayoutContent>
    </Stack>
  );
};

const ForgotLink = styled(Link)(() => ({
  width: '100%',
  textAlign: 'center',
  paddingTop: 12
}));
