import { InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';

import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { forgotPasswordSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserIcon } from 'assets/icons';

type ForgotFormProps = {
  onSubmit: (data: { email: string }) => void;
};
export const ForgotForm = ({ onSubmit }: ForgotFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm<{
    email: string;
  }>({ resolver: yupResolver(forgotPasswordSchema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2.5}>
        <InputText
          inputError={errors.email?.message}
          placeholder={'Email'}
          inputProps={{
            ...register('email')
          }}
          onChange={(e) => {
            clearErrors('email');
            setValue('email', e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end" sx={{ paddingRight: '8px' }}>
              <Stack style={{ width: '40px', alignItems: 'flex-end' }}>
                <UserIcon />
              </Stack>
            </InputAdornment>
          }
        />
        <ButtonCustom variant={'contained'} type={'submit'} label={'Submit'} />
      </Stack>
    </form>
  );
};
