import { IconButton, InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';

import { InputText } from 'app/components/elements/InputText';
import { ButtonCustom } from 'app/components/elements/ButtonCustom';
import { resetPasswordSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type ResetPasswordForm = {
  onSubmit: (data: { password: string }) => void;
  showPassword: boolean;
  setShowPassword: (val: boolean) => void;
};
export const ResetPasswordForm = ({
  onSubmit,
  showPassword,
  setShowPassword
}: ResetPasswordForm) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm<{
    password: string;
    confirmPassword: string;
  }>({ resolver: yupResolver(resetPasswordSchema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2.5}>
        <InputText
          placeholder={'Password'}
          inputError={errors.password?.message}
          inputProps={{
            ...register('password'),
            type: showPassword ? 'text' : 'password'
          }}
          onChange={(e) => {
            clearErrors('password');
            setValue('password', e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <Visibility sx={{ color: '#22374A' }} />
                ) : (
                  <VisibilityOff sx={{ color: '#22374A' }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <InputText
          placeholder={'Confirm Password'}
          inputError={errors.confirmPassword?.message}
          inputProps={{
            ...register('confirmPassword'),
            type: showPassword ? 'text' : 'password'
          }}
          onChange={(e) => {
            clearErrors('confirmPassword');
            setValue('confirmPassword', e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <Visibility sx={{ color: '#22374A' }} />
                ) : (
                  <VisibilityOff sx={{ color: '#22374A' }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <ButtonCustom variant={'contained'} type={'submit'} label={'Submit'} />
      </Stack>
    </form>
  );
};
