import React from 'react';
import { Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LayoutContent } from 'app/components/templates/LayoutContent';
import { useInjection } from './hook';
import { ResetPasswordForm } from './components/ResetPasswordForm';

export const ResetPasswordPage = () => {
  const { handleSubmitForm, showPassword, setShowPassword } = useInjection();

  return (
    <Stack width={'100%'}>
      <Helmet>
        <title>Vidatalk - Reset password</title>
      </Helmet>
      <LayoutContent
        title={'Reset password'}
        children={
          <ResetPasswordForm
            onSubmit={handleSubmitForm}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        }
      />
    </Stack>
  );
};
