import { useState, useContext, createContext, ReactNode } from 'react';
import { Organization } from 'types/organization';

type OrganizationContextType = {
  organizations: Organization[];
  selectedOrganization: Organization | null;
  setOrganizations: (val: Organization[]) => void;
  setSelectedOrganization: (val: Organization | null) => void;
};

const OrganizationsContext = createContext<OrganizationContextType>({
  organizations: [],
  selectedOrganization: null,
  setOrganizations: () => null,
  setSelectedOrganization: () => null
});

/**
 * @return useContext use context flash
 */
export function useOrganizations(): OrganizationContextType {
  return useContext(OrganizationsContext);
}

type Props = {
  children: ReactNode;
};

/**
 * @return FlashProvider provider value flash
 */
export function OrganizationsProvider({ children }: Props) {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const value = { organizations, setOrganizations, selectedOrganization, setSelectedOrganization };

  return <OrganizationsContext.Provider value={value}>{children}</OrganizationsContext.Provider>;
}
