import React, { useMemo } from 'react';

import Highcharts from 'highcharts/highstock';
import Chart from 'highcharts-react-official';

import { Board } from '../Board';
/* eslint-disable @typescript-eslint/no-explicit-any */
const LineChart = ({ projects, loading, heading, units, period, thisMonth }: any) => {
  // console.log('project',projects);
  let yText = '';
  let seriesText = '';
  let labels = {};
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const categories = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  // const categories = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

  if (units === 'time') {
    yText = 'Minutes of Activity';
    seriesText = 'Minutes';
  }
  if (units === 'freq') {
    yText = 'Number of Conversations';
    seriesText = 'Conversations';
  }
  let xText = '';
  if (period === 'month') {
    xText = 'Last 12 Months';
    labels = {
      formatter(): never {
        // @ts-ignore
        // eslint-disable-next-line
        return monthNames[this.value + thisMonth];
      }
    };
  }
  if (period === 'day') {
    xText = 'Day of the Month';
  }

  const options = useMemo(
    () => ({
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          enabled: true,
          text: xText
        },
        categories,
        labels
      },
      yAxis: {
        title: {
          enabled: true,
          text: yText
        }
      },
      series: [
        {
          type: 'line',
          name: seriesText,
          data: projects
        }
      ]
    }),
    [projects]
  );

  return (
    <Board loading={loading} heading={heading}>
      <Chart highcharts={Highcharts} options={options} />
    </Board>
  );
};

export default LineChart;
