import { useOrganizations } from 'contexts/organizations';
import get from 'lodash/get';
import { useState } from 'react';
import { updateMyOrganization } from 'services/organization';
import { useFlash } from 'contexts/flash';
import { StorageServices } from 'services/storage';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

const defaultPassword = '********';
export type SettingForm = {
  organizationName: string;
  username: string;
  password: string;
  pin?: boolean;
  tour?: boolean;
  logout?: boolean;
  support?: boolean;
  speech2T?: boolean;
  sharecare?: boolean;
  t2TTranslate?: boolean;
  vInterpreter?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services?: any;
};
const storage = new StorageServices();

export const useInjection = () => {
  const role = storage.getRole();
  const { setFlash } = useFlash();
  const [initForm, setInitForm] = useState(false);
  const { selectedOrganization, setOrganizations, organizations } = useOrganizations();
  const account = get(selectedOrganization, 'accounts[0]');
  const { asyncCallback: handleSaveSettings } = useAsyncCallback(
    async (formData: SettingForm) => {
      if (!selectedOrganization) return;
      const streaming = {
        enableServices: [],
        errors: selectedOrganization?.services?.errors,
        optOut: selectedOrganization?.services?.optOut
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData.services.map((item: any) => {
        // @ts-ignore
        streaming[item.name] = item.data;
        if (item.enable) {
          // @ts-ignore
          streaming.enableServices = [...streaming.enableServices, item.name];
        }
      });
      const payload = {
        account: {
          username: formData.username,
          password: formData.password === defaultPassword ? undefined : formData.password
        },
        organization: {
          name: formData.organizationName,
          services: {
            pin: formData.pin,
            tour: formData.tour,
            logout: formData.logout,
            sharecare: formData.sharecare,
            speech2T: formData.speech2T,
            support: formData.support,
            t2TTranslate: formData.t2TTranslate,
            vInterpreter: formData.vInterpreter,
            streaming
          }
        }
      };
      const { organization, account: updatedAccount } = await updateMyOrganization(
        selectedOrganization.id,
        payload
      );
      const findId = selectedOrganization.parentOrganizationId
        ? selectedOrganization.parentOrganizationId
        : selectedOrganization.id;
      const idx = organizations.findIndex((item) => item.id === findId);
      const raw = [...organizations];
      if (findId === selectedOrganization.id) {
        raw[idx] = {
          ...selectedOrganization,
          ...organization,
          accounts: [{ ...account, ...updatedAccount }]
        };
      } else {
        const old = raw[idx];
        const childIdx = (old.childrenOrganizations || []).findIndex(
          (item) => item.id === selectedOrganization.id
        );
        if (childIdx < 0) return;
        const children = old.childrenOrganizations || [];
        children[childIdx] = {
          ...children[childIdx],
          ...organization,
          accounts: [{ ...account, ...updatedAccount }]
        };
        old.childrenOrganizations = children;
        raw[idx] = old;
      }
      setOrganizations(raw);

      setFlash({ type: 'success', message: 'Settings updated successfully!' });
    },
    [selectedOrganization]
  );
  const streamLines = selectedOrganization?.services?.streaming || {};

  return {
    account,
    selectedOrganization,
    defaultForm: {
      organizationName: selectedOrganization?.name || '',
      username: account?.username || '',
      password: defaultPassword,
      pin: !!selectedOrganization?.services?.pin,
      tour: !!selectedOrganization?.services?.tour,
      logout: !!selectedOrganization?.services?.logout,
      sharecare: !!selectedOrganization?.services?.sharecare,
      speech2T: !!selectedOrganization?.services?.speech2T,
      support: !!selectedOrganization?.services?.support,
      t2TTranslate: !!selectedOrganization?.services?.t2TTranslate,
      vInterpreter: !!selectedOrganization?.services?.vInterpreter,
      services: Object.keys(streamLines)
        .filter((key) => ['languageLine'].includes(key))
        .map((key, index) => ({
          id: index + 1,
          name: key,
          // @ts-ignore
          data: streamLines[key] ? streamLines[key] : [],
          enable: (get(streamLines, 'enableServices', []) as string[]).includes(key)
        }))
    },
    handleSaveSettings,
    initForm,
    setInitForm,
    role
  };
};
