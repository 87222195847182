export const { innerWidth: widthScreen } = window;

export const ROLE = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  ROOT_ADMIN: 'ROOT_ADMIN'
};

export const capitalizeFirstLetter = (text: string): string =>
  text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
