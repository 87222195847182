import Cookies from 'js-cookie';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const ROLE_KEY = 'role';
const REFRESH_TOKEN_KEY = 'refreshToken';
const config = {
  secure: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SECURE_COOKIE === 'true'
};
/**
 * @return TokenStorage service
 */
export class StorageServices {
  /**
   * @returns function handle set token in cookies
   */
  setAccessToken(accessToken: string, key = ACCESS_TOKEN_KEY) {
    Cookies.set(key, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getAccessToken(key = ACCESS_TOKEN_KEY): string | undefined {
    return Cookies.get(key);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeAccessToken(key = ACCESS_TOKEN_KEY) {
    return Cookies.remove(key);
  }
  /**
   * @returns function handle set token in cookies
   */
  setRefreshToken(accessToken: string) {
    Cookies.set(REFRESH_TOKEN_KEY, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getRefreshToken(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeRefreshToken() {
    return Cookies.remove(REFRESH_TOKEN_KEY);
  }
  /**
   * @returns function handle set role in cookies
   */
  setRole(accessToken: string, key = ROLE_KEY) {
    Cookies.set(key, accessToken, config);
  }

  /**
   * @returns function handle get role in cookies
   */
  getRole(key = ROLE_KEY): string | undefined {
    return Cookies.get(key);
  }

  /**
   * @returns function handle remove role in cookies
   */
  removeRole(key = ROLE_KEY) {
    return Cookies.remove(key);
  }
}
