import axios from 'axios';
import { ContractForm } from '../app/components/modules/ControlPanels/Account/hooks';

type CreateOrganizationPayload = {
  organization: {
    name: string;
    parentOrganizationId?: number;
    services?: unknown;
    limitLicense?: number;
    timeZone?: string;
    tourSession?: boolean;
  };
  account: {
    name?: string;
    username: string;
    password: string;
  };
};
export const createOrganization = (payload: CreateOrganizationPayload) => {
  return axios.post('/organizations', payload);
};
export const listOrganization = async (query?: {
  page?: number;
  size?: number;
  isMasterOrganization?: boolean;
}) => {
  const { data } = await axios.get('/organizations', { params: query });
  return data;
};

export const getContractDetail = async (organazationId: number) => {
  const { data } = await axios.get(`/organizations/${organazationId}/contract-detail`);
  return data;
};

export const updateContract = async (organazationId: number, payload: ContractForm) => {
  const { data } = await axios.put(`/organizations/${organazationId}/account`, payload);
  return data;
};

export const listUsers = async (query?: { page?: number; size?: number; keyword?: string }) => {
  const { data } = await axios.get(`/admins/users`, { params: query });
  return data;
};
