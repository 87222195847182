/* eslint-disable @typescript-eslint/no-explicit-any */

import apiClient from 'axios';

export const getTalk2TextFreq = (params?: any) =>
  apiClient
    .get('/insights/t2tfreq', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getText2SpeechFreq = (params?: any) =>
  apiClient
    .get('/insights/t2sfreq', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getText2TranslateFreq = (params?: any) =>
  apiClient
    .get('/insights/t2transfreq', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getCommonExchanges = (params?: any) =>
  apiClient
    .get('/insights/commonexchanges', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getLanguagesSpoken = (params?: any) =>
  apiClient
    .get('/insights/languagesspoken', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getLanguagesTranslated = (params?: any) =>
  apiClient
    .get('/insights/languagestranslated', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getConversations = (params?: any) =>
  apiClient
    .get('/insights/conversations', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));

export const getAnnualConversations = (params?: any) =>
  apiClient
    .get('/insights/conversations', { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
