import * as yup from 'yup';
import { SettingForm } from '../app/components/modules/ControlPanels/Settings/hooks';
import { ObjectSchema } from 'yup';

// const latLongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const numberRegex = /^[0-9.]/g;

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required').matches(emailRegex, 'Email invalid')
});
export const adminLoginSchema = yup.object().shape({
  account: yup.string().required('Email is required').matches(emailRegex, 'Email invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
});

export const enterpriseLoginSchema = yup.object().shape({
  account: yup.string().required('Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
});

export const createOrganizationSchema = yup.object().shape({
  organizationName: yup.string().required('Organization name is required'),
  limitLicense: yup.number().required('Limit of licenses is required'),
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
});

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  newPassword: yup
    .string()
    .required('Password is required')
    .notOneOf(
      [yup.ref('currentPassword')],
      'The new password cannot be the same as the current password. Please choose a different password'
    )
    .min(8, 'Password must be at least 8 characters'),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match')
});
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required')
});

export const settingSchema: ObjectSchema<SettingForm> = yup.object().shape({
  organizationName: yup.string().required('Customer name is required'),
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  pin: yup.boolean(),
  logout: yup.boolean(),
  tour: yup.boolean(),
  support: yup.boolean(),
  speech2T: yup.boolean(),
  sharecare: yup.boolean(),
  t2TTranslate: yup.boolean(),
  vInterpreter: yup.boolean(),
  services: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      name: yup.string().required('Service name is required'),
      data: yup.array().of(
        yup.object().shape({
          pinCode: yup.string().required('Pin code is required'),
          activationCode: yup.string().required('Activation code is required')
        })
      )
    })
  )
});

export const contractSchema = yup.object().shape({
  limitLicense: yup.number().required('Limit of licenses is required'),
  contractEndDate: yup.string().required('Contract end date is required')
});
